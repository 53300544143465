<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcStateCampIndex }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcCurrentHome"
        :showCamp="false"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->
      <div class="page-body container mx-med pt-3">
        <section class="section-3">
          <div class="row">
            <InfoCard
              v-for="(info, index) in campTilesFiltered"
              :key="index"
              :obj="info"
              @set_program="handleSetProgram($event)"
            />
          </div>
        </section>
      </div>
    </div>
    <!-- / Page Body -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'

export default {
  name: 'camp-officers-home',
  mixins: [translationMixin, stateBreadcrumbMixin],
  data() {
    return {
      translations: {},
      campHomeTiles: [
        {
          class: 'col col-6',
          title: '', // Camp Elections
          content: '',
          link: '/officers/directory/membership-dinner-speakers',
          label: 'View More', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Camp Elections
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Treasury,
          content: '',
          link: '/officers/directory/pastor-banquet-speakers',
          label: '', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Camp Treasury
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Goal Planner
          content: '',
          link: '/officers/directory/camp-officer-selections',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Camp Goal Planner,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Security Manager
          content: '',
          link: '/officers/directory/qualified-speakers',
          label: '', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Security Manager
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Member
          content: '',
          link: '/officers/directory/scripture-blitz-speakers',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Camp Member
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/directory-camp',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg', //'svgs/program-resources.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/members-list',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/testimony-speakers',
          label: '', // View More
          img: {
            src: 'svgs/Presenters.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/officers/directory/widows-list',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/reports/report-center/st',
          label: '', // View More
          img: {
            src: 'svgs/church-reports.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
      ],
      defaultTiles: [
        {
          class: 'col col-6',
          title: '', // Validate Membership Applications
          content: '',
          link: '/officers/camp/validate-membership-applications/cm',
          label: '', // View More
          img: {
            src: 'svgs/application.svg',
            alt: '', // Validate Membership Applications
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Comunication Preferences
          content: '',
          link: '/officers/camp/communication-preferences',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Comunication Preferences
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: '', // Camp Profile
          content: '',
          link: '/officers/camp/camp-profile/cm',
          itemId: '1B3A2CD6-63D0-4050-891F-54549C33B7A8', // The sitecore ItemId for ChurchMinistry Program
          label: 'View More',
          img: {
            src: 'yellow-tent-icon.png',
            alt: '', // Camp Profile
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  components: {
    campSelector: CampSelect,
    InfoCard: InfoCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
    }),
    async page_load() {},
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.link)
    },
    loadTranslations() {
      this.campHomeTiles[0].title = this.translations.tcAnnualMembershipDinnerSpeakers
      this.campHomeTiles[0].content = this.translations.tcAnnualMembershipDinnerSpeakersDesc
      this.campHomeTiles[0].label = this.translations.tcViewMore
      this.campHomeTiles[0].img.alt = this.translations.tcAnnualMembershipDinnerSpeakers

      this.campHomeTiles[1].title = this.translations.tcAnnualPastorsEventSpeakers
      this.campHomeTiles[1].content = this.translations.tcAnnualPastorsEventSpeakersDesc
      this.campHomeTiles[1].label = this.translations.tcViewMore
      this.campHomeTiles[1].img.alt = this.translations.tcAnnualPastorsEventSpeakers

      this.campHomeTiles[2].title = this.translations.tcCampOfficerSelections
      this.campHomeTiles[2].content = this.translations.tcCampOfficerSelectionsDesc
      this.campHomeTiles[2].label = this.translations.tcViewMore
      this.campHomeTiles[2].img.alt = this.translations.tcCampOfficerSelections

      this.campHomeTiles[3].title = this.translations.tcQualifiedChurchSpeakers
      this.campHomeTiles[3].content = this.translations.tcQualifiedChurchSpeakersDesc
      this.campHomeTiles[3].label = this.translations.tcViewMore
      this.campHomeTiles[3].img.alt = this.translations.tcQualifiedChurchSpeakers

      this.campHomeTiles[4].title = this.translations.tcInternationalScriptureBlitzParticipants
      this.campHomeTiles[4].content = this.translations.tcInternationalScriptureBlitzParticipantsDesc
      this.campHomeTiles[4].label = this.translations.tcViewMore
      this.campHomeTiles[4].img.alt = this.translations.tcInternationalScriptureBlitzParticipants

      this.campHomeTiles[5].title = this.translations.tcStateDirectoryCampIndex
      this.campHomeTiles[5].content = this.translations.tcStateDirectoryCampIndexDesc
      this.campHomeTiles[5].label = this.translations.tcViewMore
      this.campHomeTiles[5].img.alt = this.translations.tcStateDirectoryCampIndex

      this.campHomeTiles[6].title = this.translations.tcStateMembersList
      this.campHomeTiles[6].content = this.translations.tcStateMembersListDesc
      this.campHomeTiles[6].label = this.translations.tcViewMore
      this.campHomeTiles[6].img.alt = this.translations.tcStateMembersList

      this.campHomeTiles[7].title = this.translations.tcTestimonySpeakers
      this.campHomeTiles[7].content = this.translations.tcTestimonySpeakersDesc
      this.campHomeTiles[7].label = this.translations.tcViewMore
      this.campHomeTiles[7].img.alt = this.translations.tcTestimonySpeakers

      this.campHomeTiles[8].title = this.translations.tcWidowsList
      this.campHomeTiles[8].content = this.translations.tcWidowsListDesc
      this.campHomeTiles[8].label = this.translations.tcViewMore
      this.campHomeTiles[8].img.alt = this.translations.tcWidowsList

      this.campHomeTiles[9].title = this.translations.tcStateReports
      this.campHomeTiles[9].content = this.translations.tcStateReportsDesc
      this.campHomeTiles[9].label = this.translations.tcViewMore
      this.campHomeTiles[9].img.alt = this.translations.tcStateReports

      this.defaultTiles[0].title = this.translations.tcValidateMembershipApplicationsTitle
      this.defaultTiles[0].content = this.translations.tcValidateMembershipApplicationsDescription
      this.defaultTiles[0].label = this.translations.tcViewMore
      this.defaultTiles[0].img.alt = this.translations.tcValidateMembershipApplicationsTitle

      this.defaultTiles[1].title = this.translations.tcCommunicationPreferencesTitle
      this.defaultTiles[1].content = this.translations.tcCommunicationPreferencesDescription
      this.defaultTiles[1].label = this.translations.tcViewMore
      this.defaultTiles[1].img.alt = this.translations.tcCommunicationPreferencesTitle

      this.defaultTiles[2].title = this.translations.tcCampProfileTitle
      this.defaultTiles[2].content = this.translations.tcCampProfileDescription
      this.defaultTiles[2].label = this.translations.tcViewMore
      this.defaultTiles[2].img.alt = this.translations.tcCampProfileTitle
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'person-search', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error('Error during created(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      officerSelectStatekey: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userOrgKey: 'user/userOrgKey',
    }),
    allowPage() {
      return true
    },
    campTilesFiltered() {
      return this.campHomeTiles
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.church-ministry {
  .page-body {
    margin-top: 68px;
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 36px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 80px;
    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
    > .row {
      margin-bottom: 28px;
    }
    .col {
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
      }
      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
